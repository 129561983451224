<template>
  <div class="container-fluid bg-light py-3">
    <main class="container-xl">
        <h1>Aviso de Privacidad</h1>
        <iframe src="https://docs.google.com/viewer?url=http%3A%2F%2Fwww.web-sait.com.mx%2Fwp-content%2Fuploads%2F2018%2F11%2Faviso_privacidad_websait.pdf&hl=es&embedded=true" class="gde-frame" style="width:100%; height:500px; border: none;" scrolling="no"></iframe>
    </main>
  </div>
</template>
<script>
export default {
  name: 'AvisoPriv'
}
</script>